<script setup lang="ts">
import type { SelectRootEmits, SelectRootProps } from 'radix-vue';
import { useForwardPropsEmits } from 'radix-vue';
import {
  Select as ShadSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from './components';
import { cn } from '../../lib/utils';
import { type HTMLAttributes, computed } from 'vue';

type Option = {
  label: string;
  value: string | number;
};

type Props = SelectRootProps & {
  options: Option[];
  label?: string;
  disabled?: boolean;
  labelStyles?: string;
  placeHolder?: string;
  class?: HTMLAttributes['class'];
}

const props = defineProps<Props>();
const emits = defineEmits<SelectRootEmits>();

const delegatedProps = computed(() => {
  const { options, label, disabled, labelStyles, placeHolder, ...forwaredProps } = props;
  return forwaredProps;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ShadSelect
    v-bind="forwarded"
    :disabled="props.disabled"
  >
    <SelectTrigger :class="cn('w-full :dark-bg-gray-800', props.class)">
      <SelectValue
        :placeholder="placeHolder"
      />
    </SelectTrigger>
    <SelectContent>
      <SelectGroup>
        <SelectItem
          v-for="option in props.options"
          :key="option.label"
          :value="String(option.value)"
          :class="cn(props.class)"
        >
          {{ option.label }}
        </SelectItem>
      </SelectGroup>
    </SelectContent>
  </ShadSelect>
</template>
